import React from 'react';
import { Navigate } from 'react-router-dom';
import { useApp } from '../../providers/UserProvider';

function ProtectedRoute({ children, roles = [1, 2, 3] }) {
  const { isAuthenticated } = useApp();
  const authenticatedUser = JSON.parse(
    localStorage.getItem('authenticatedUser'),
  );

  if (!isAuthenticated()) {
    return <Navigate to="/" replace />;
  }

  if (!roles.includes(authenticatedUser.role.id)) {
    return <Navigate to="/dashboard" replace />;
  }

  return children;
}

export default ProtectedRoute;
