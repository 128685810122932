import React from 'react';
import styled from 'styled-components';
import LoadingOverlay from 'react-loading-overlay';
import ClipLoader from 'react-spinners/ClipLoader';

const StyledLoader = styled(LoadingOverlay)`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.9);
  transition: opacity 0.5s;
  opacity: 1;
  z-index: 999999;
  color: #fff;
  display: ${props => {
    return props.active ? 'block' : 'none';
  }};

  ._loading_overlay_content {
    position: fixed; /* or absolute */
    top: 40%;
    left: 45%;
  }
`;

function MyLoadingOverlay() {
  return (
    <StyledLoader active>
      <LoadingOverlay active spinner={<ClipLoader color="#fff" size={150} />} />
    </StyledLoader>
  );
}

export default MyLoadingOverlay;
